/**
 * Third-party libraries.
 */

/**
 * Project components.
 */
import { ChibiInvestigating, ChibiPanic, ChibiRocket, ChibiRunning, ChibiShocked, CircleBlue, CircleYellow, FireOrange, FireYellow, FlowerYellow, Lego, Paper, PencilMechanical, SquareRed, WigglyYellow } from "@/components/client/images";

/**
 * A scene with a burning machine and chibi characters.
 */
export function BurningScene() {
  return <div className="inline-flex flex-col items-start justify-start gap-2.5" data-sentry-component="BurningScene" data-sentry-source-file="buring-scene.tsx">
      <div className="relative h-[500px] w-[670px]">
        <div id="platform-shadow" className="absolute left-[15px] top-[195px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="596" height="256" viewBox="0 0 596 256" fill="none" data-sentry-element="svg" data-sentry-source-file="buring-scene.tsx">
            <path opacity="0.75" d="M0 255.518H596V0.893799L0 1.39092V255.518Z" fill="url(#paint0_linear_1076_21666)" data-sentry-element="path" data-sentry-source-file="buring-scene.tsx" />
            <defs data-sentry-element="defs" data-sentry-source-file="buring-scene.tsx">
              <linearGradient id="paint0_linear_1076_21666" x1="298.009" y1="114.224" x2="298.009" y2="235.325" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="buring-scene.tsx">
                <stop stopColor="#E8EDF0" data-sentry-element="stop" data-sentry-source-file="buring-scene.tsx" />
                <stop offset="1" stopColor="white" data-sentry-element="stop" data-sentry-source-file="buring-scene.tsx" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div id="platform" className="absolute left-[15px] top-[25px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="596" height="343" viewBox="0 0 596 343" fill="none" data-sentry-element="svg" data-sentry-source-file="buring-scene.tsx">
            <path d="M595.822 171.678L297.257 0.477051L0 171.678L298.616 342.879L595.822 171.678Z" fill="white" data-sentry-element="path" data-sentry-source-file="buring-scene.tsx" />
          </svg>
        </div>
        <div className="absolute left-[58.43px] top-[55.59px] h-[182.44px] w-[177.06px]">
          <Lego data-sentry-element="Lego" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[48.71px] top-[104.83px] animate-wave">
          <CircleYellow data-sentry-element="CircleYellow" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[84.71px] top-[40.83px] animate-wave animate-delay-300">
          <SquareRed data-sentry-element="SquareRed" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[144.71px] top-[36.83px] animate-wave animate-delay-700">
          <CircleBlue data-sentry-element="CircleBlue" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[183.44px] top-[8.59px] animate-wave animate-delay-200">
          <FlowerYellow data-sentry-element="FlowerYellow" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[135.09px] top-[196.01px]">
          <ChibiRunning data-sentry-element="ChibiRunning" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[274.50px] top-[243.17px]">
          <ChibiInvestigating data-sentry-element="ChibiInvestigating" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[213.71px] top-[144.18px]">
          <ChibiShocked data-sentry-element="ChibiShocked" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[466.65px] top-[131.83px]">
          <ChibiPanic data-sentry-element="ChibiPanic" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[451.19px] top-[5.48px] animate-wave animate-delay-500">
          <ChibiRocket data-sentry-element="ChibiRocket" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[284.71px] top-[20.77px] animate-wave">
          <FireOrange data-sentry-element="FireOrange" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[302.71px] top-[84.77px] animate-wave animate-delay-300">
          <FireYellow data-sentry-element="FireYellow" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[224.71px] top-[78.77px]">
          <PencilMechanical data-sentry-element="PencilMechanical" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[546.91px] top-[82.72px] animate-spin-wave animate-delay-200">
          <WigglyYellow data-sentry-element="WigglyYellow" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[596.91px] top-[132.72px] animate-wave animate-delay-500">
          <CircleBlue data-sentry-element="CircleBlue" data-sentry-source-file="buring-scene.tsx" />
        </div>
        <div className="absolute left-[526.91px] top-[172.72px]">
          <Paper data-sentry-element="Paper" data-sentry-source-file="buring-scene.tsx" />
        </div>
      </div>
    </div>;
}