import type { SVGProps } from "react";
export const ApplicationLogo = (props: SVGProps<SVGSVGElement>) => <svg width="1067" height="1067" viewBox="0 0 1067 1067" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="ApplicationLogo" data-sentry-source-file="application-logo.tsx">
    <path d="M839.588 652.324C817.721 602.061 798.276 550.747 777.301 500.088C774.024 492.175 769.078 484.608 763.533 478.04C744.897 455.963 725.449 434.572 706.809 412.5C706.78 412.465 706.751 412.43 706.723 412.395C700.036 404.353 690.444 399.288 679.985 399.288H532.603H385.22C374.761 399.288 365.169 404.353 358.484 412.395C358.455 412.43 358.425 412.465 358.396 412.5C339.755 434.572 320.308 455.963 301.673 478.04C296.127 484.608 291.182 492.175 287.906 500.088C266.929 550.747 247.484 602.061 225.616 652.324C202.002 706.606 179.766 760.736 188.707 821.909C188.811 822.625 188.715 823.371 188.724 824.103C188.953 844.721 199.961 855.981 220.679 855.987C324.655 856.019 428.629 855.984 532.603 855.981C636.577 855.984 740.551 856.019 844.525 855.987C865.244 855.981 876.252 844.721 876.483 824.103C876.49 823.371 876.394 822.625 876.497 821.909C885.44 760.736 863.203 706.606 839.588 652.324Z" fill="#FF5555" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M406.872 434.951C378.861 452.493 360.813 470.794 349.877 483.564C313.685 525.819 307.983 562.814 267.736 667.96C256.514 697.279 246.866 720.77 240.915 735.013" stroke="#FF7C76" strokeWidth="33.3333" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M366.838 356.234H446.904V466.185H366.838V356.234Z" fill="#FF5555" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <mask id="mask0_4427_1873" style={{
    maskType: "luminance"
  }} maskUnits="userSpaceOnUse" x="366" y="356" width="81" height="111" data-sentry-element="mask" data-sentry-source-file="application-logo.tsx">
      <path d="M366.84 466.184H446.905V356.234H366.84V466.184Z" fill="white" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    </mask>
    <g mask="url(#mask0_4427_1873)" data-sentry-element="g" data-sentry-source-file="application-logo.tsx">
      <path d="M366.838 365.632C379.277 363.394 392.492 361.386 406.431 359.744C420.588 358.075 434.108 356.948 446.904 356.234" stroke="#B8423F" strokeWidth="28" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    </g>
    <path d="M615.688 345.851H695.753V455.802H615.688V345.851Z" fill="#FF5555" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <mask id="mask1_4427_1873" style={{
    maskType: "luminance"
  }} maskUnits="userSpaceOnUse" x="616" y="346" width="81" height="110" data-sentry-element="mask" data-sentry-source-file="application-logo.tsx">
      <path d="M616 455.95H696.067V346H616V455.95Z" fill="white" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    </mask>
    <g mask="url(#mask1_4427_1873)" data-sentry-element="g" data-sentry-source-file="application-logo.tsx">
      <path d="M693.431 364.061C680.993 361.823 667.778 359.815 653.838 358.173C639.681 356.505 626.161 355.377 613.365 354.663" stroke="#B8423F" strokeWidth="28" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    </g>
    <path d="M713.172 623.53C713.172 723.257 632.329 804.1 532.602 804.1C432.878 804.1 352.034 723.257 352.034 623.53C352.034 523.804 432.878 442.961 532.602 442.961C632.329 442.961 713.172 523.804 713.172 623.53Z" fill="#F89D35" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M684.673 620.708C684.673 704.694 616.589 772.779 532.603 772.779C448.617 772.779 380.534 704.694 380.534 620.708C380.534 536.723 448.617 468.638 532.603 468.638C616.589 468.638 684.673 536.723 684.673 620.708Z" fill="#FFBF5A" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M1045.02 369.719C1040.78 353.512 1035.34 331.501 1020.72 309.765C997.298 274.933 963.563 259.941 946.006 252.139C886.447 225.67 734.814 205.786 532.603 209.432C330.39 205.786 178.759 225.67 119.199 252.139C101.642 259.941 67.9063 274.933 44.4839 309.765C29.8682 331.501 24.4273 353.512 20.1851 369.719C12.5313 398.961 11.4076 424.8 12.2271 444.119C11.8646 450.64 12.8452 457.373 15.3761 464.068V464.069C23.9355 486.721 48.2413 502.031 75.6433 502.031H213.197C240.6 502.031 264.906 486.721 273.465 464.069V464.068C275.646 458.293 276.66 452.494 276.67 446.825C276.675 446.825 276.681 446.825 276.686 446.825C276.677 446.655 276.667 446.441 276.659 446.26C276.647 445.185 276.602 444.114 276.518 443.05C276.447 441.144 276.391 438.969 276.377 436.52C276.275 419.491 278.239 406.197 278.653 404.063C284.49 373.927 406.432 360.744 406.432 360.744C436.694 357.36 479.894 353.976 532.603 354.867C585.312 353.976 628.512 357.36 658.774 360.744C658.774 360.744 780.716 373.927 786.552 404.063C786.965 406.197 788.931 419.491 788.829 436.52C788.815 438.967 788.757 441.143 788.688 443.049C788.606 444.114 788.558 445.185 788.547 446.26C788.537 446.441 788.529 446.655 788.519 446.825C788.525 446.825 788.531 446.825 788.536 446.825C788.546 452.494 789.56 458.293 791.742 464.068V464.069C800.3 486.721 824.606 502.031 852.008 502.031H989.562C1016.96 502.031 1041.27 486.721 1049.83 464.069V464.068C1052.36 457.373 1053.34 450.64 1052.98 444.119C1053.8 424.8 1052.67 398.961 1045.02 369.719Z" fill="#FF5555" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M23.9077 410.491C60.215 405.373 102.355 401.734 149.23 401.828C194.654 401.919 235.581 405.497 271.011 410.491" stroke="#B8423F" strokeWidth="28" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M798.148 410.491C834.456 405.373 876.596 401.734 923.471 401.828C968.895 401.919 1009.82 405.497 1045.25 410.491" stroke="#B8423F" strokeWidth="28" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M132.834 291.832C158.261 281.164 199.787 265.68 252.857 255.119C309.682 243.81 348.759 244.313 481.388 243.587C511.944 243.42 566.46 243.082 636.93 242.41" stroke="#FF7C76" strokeWidth="33.3333" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <mask id="mask2_4427_1873" style={{
    maskType: "luminance"
  }} maskUnits="userSpaceOnUse" x="394" y="594" width="67" height="67" data-sentry-element="mask" data-sentry-source-file="application-logo.tsx">
      <path d="M394.508 594.752H460.29V660.534H394.508V594.752Z" fill="white" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    </mask>
    <g mask="url(#mask2_4427_1873)" data-sentry-element="g" data-sentry-source-file="application-logo.tsx">
      <g opacity="0.449997" data-sentry-element="g" data-sentry-source-file="application-logo.tsx">
        <path d="M394.508 627.644C394.508 645.808 409.233 660.535 427.399 660.535C445.564 660.535 460.29 645.808 460.29 627.644C460.29 609.479 445.564 594.752 427.399 594.752C409.233 594.752 394.508 609.479 394.508 627.644Z" fill="#EB3751" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
      </g>
    </g>
    <path d="M502.783 657.296C509.463 668.039 521.324 674.44 533.832 674.004C552.093 673.366 561.674 658.502 562.421 657.296" stroke="#463233" strokeWidth="20" strokeMiterlimit="10" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <path d="M460.29 632.364C448.954 632.364 439.764 623.174 439.764 611.838V587.919C439.764 576.582 448.954 567.392 460.29 567.392C471.626 567.392 480.816 576.582 480.816 587.919V611.838C480.816 623.174 471.626 632.364 460.29 632.364Z" fill="#463233" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    <mask id="mask3_4427_1873" style={{
    maskType: "luminance"
  }} maskUnits="userSpaceOnUse" x="602" y="594" width="67" height="67" data-sentry-element="mask" data-sentry-source-file="application-logo.tsx">
      <path d="M602.304 594.752H668.085V660.534H602.304V594.752Z" fill="white" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
    </mask>
    <g mask="url(#mask3_4427_1873)" data-sentry-element="g" data-sentry-source-file="application-logo.tsx">
      <g opacity="0.449997" data-sentry-element="g" data-sentry-source-file="application-logo.tsx">
        <path d="M668.085 627.644C668.085 645.809 653.36 660.536 635.193 660.536C617.029 660.536 602.303 645.809 602.303 627.644C602.303 609.479 617.029 594.752 635.193 594.752C653.36 594.752 668.085 609.479 668.085 627.644Z" fill="#EB3751" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
      </g>
    </g>
    <path d="M602.304 632.364C613.64 632.364 622.83 623.174 622.83 611.838V587.919C622.83 576.582 613.64 567.392 602.304 567.392C590.967 567.392 581.777 576.582 581.777 587.919V611.838C581.777 623.174 590.967 632.364 602.304 632.364Z" fill="#463233" data-sentry-element="path" data-sentry-source-file="application-logo.tsx" />
  </svg>;